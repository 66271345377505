import * as React from 'react'
import './GetProductImages.css'
import Carousel from 'react-bootstrap/Carousel'
import useWindowSize from '../useWindowSize/useWindowSize'
import WhatsInTheBox from '../WhatsInTheBox/WhatsInTheBox'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

//pineapple
/*
import p1 from'../../images/PImgs/pineapple1.png'
import p2 from'../../images/PImgs/pineapple2.png'
import p3 from'../../images/PImgs/pineapple3.png'
import p4 from'../../images/PImgs/pineapplenutfacts.png'
*.

//orange
/*
import o1 from '../../images/OImgs/orange1.png'
import o2 from '../../images/OImgs/orange2.png'
import o3 from '../../images/OImgs/orange3.png'
import o4 from '../../images/OImgs/orange4.png'
import o5 from '../../images/OImgs/orangesticker1.png'
import o6 from '../../images/OImgs/orangesticker2.png'
import o7 from '../../images/OImgs/orangesticker3.png'
import o8 from '../../images/OImgs/backorangesticker1.png'
*/
//blue raspberry
/*
import b1 from '../../images/BImgs/rasberry1.png'
import b2 from '../../images/BImgs/rasberry2.png'
import b3 from '../../images/BImgs/rasberry3.png'
import b4 from '../../images/BImgs/nutfactsv2.png'



//mango
import m1 from '../../images/MImgs/mango1.png'
import m2 from '../../images/MImgs/mango2.png'
import m3 from '../../images/MImgs/mango3.png'
import m4 from '../../images/MImgs/mangonutfacts.png'
import { node } from 'prop-types'
*/
/*
//strawberry

import s1 from '../../images/SImgs/strawberry1.png'
import s2 from '../../images/SImgs/strawberry2.png'
import s3 from '../../images/SImgs/strawberry3.png'
import s4 from '../../images/SImgs/strawberry4.png'
import s5 from '../../images/SImgs/strawberrysticker1.png'
import s6 from '../../images/SImgs/strawberrysticker2.png'
import s7 from '../../images/SImgs/strawberrysticker3.png'
import s8 from '../../images/SImgs/backstrawberrysticker1.png'

//Watermelon

import w1 from '../../images/WImgs/watermelon1.png'
import w2 from '../../images/WImgs/watermelon2.png'
import w3 from '../../images/WImgs/watermelon3.png'
import w4 from '../../images/WImgs/watermelon4.png'
import w5 from '../../images/WImgs/watermelonsticker1.png'
import w6 from '../../images/WImgs/watermelonsticker2.png'
import w7 from '../../images/WImgs/watermelonsticker3.png'
import w8 from '../../images/WImgs/backwatermelonsticker1.png'
*/
/*const images = {
  '0': [p1, p2, p3, p4],
  '1': [o1, o2, o3, o4],
  '2': [s1, s2, s3, s4],
  '3': [b1, b2, b3, b4],
  '4': [w1, w2, w3, w4],
  '5': [m1, m2, m3, m4],
}*/

//pineapple
const p1 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/p1.png?alt=media&token=81787ac5-afd2-4945-9389-579adf2b0b57'
const p2 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/p2.png?alt=media&token=6e7fb786-e255-46a2-8a21-01400a04d8c0'
const p3 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/p3.png?alt=media&token=4daf5ffc-b34a-4c26-b594-6e51c1a58d06'
const p4 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/p4.png?alt=media&token=945aa078-5796-4cec-acde-2d54e51da409'
const p5 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/pineapplesticker1.png?alt=media&token=9dc9819a-a8a4-4df6-a91f-a632aa56e31e'
const p6 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/pineapplesticker2.png?alt=media&token=27d1d07e-eea8-49c3-80c8-1c4436269136'
const p7 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/pineapplesticker3.png?alt=media&token=994f3ef6-2b28-4063-96df-5751092accc4'

//mango 
const m1 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/m1.png?alt=media&token=48f37020-3265-49e1-8418-504262b05efc'
const m2 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/m2.png?alt=media&token=7f72df63-2629-41bb-944f-8ba8dd24cf88'
const m3 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/m3.png?alt=media&token=f8896931-245d-4057-bf02-6e55f0e06b75'
const m4 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/m4.png?alt=media&token=a4bf1fd2-826c-4540-a1cf-feaad2ffe49d'
const m5 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/mangosticker1.png?alt=media&token=8c29488e-8110-4785-bb07-2dfc3318dea3'
const m6 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/mangosticker2.png?alt=media&token=48ec170f-1542-4d0b-9be9-949899dfef89'
const m7 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/mangosticker3.png?alt=media&token=9dacc539-bb26-4638-844e-ef6313b4dcfb'

//blue raspberry
const b1 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/b1.png?alt=media&token=dd4c7d9d-c059-4f24-874d-b2cdffe4beff'
const b2 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/b2.png?alt=media&token=dabe6017-c108-435b-93c8-b26d87c3fd60'
const b3 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/b3.png?alt=media&token=2ce05620-2bb1-4e05-a1cd-027433b3de15'
const b4 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/b4.png?alt=media&token=d725c2db-5ab8-4e45-882d-1b56509435e0'
const b5 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/blue%20rasberrysticker1.png?alt=media&token=ba790f87-6c58-4b1e-81db-5acfcf1ef696'
const b6 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/blue%20rasberrysticker2.png?alt=media&token=26a04ec7-2b26-4b9c-8953-6bf1e2f6bb32'
const b7 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/blue%20rasberrysticker3.png?alt=media&token=b87d72aa-5e77-413f-a355-a96e5ee4106f'


const pImages = {
  '1404fd14-5139-5132-8e12-2778fbd54378':[<StaticImage src = {b5} loading = 'lazy'/>, <StaticImage src = {b6} loading = 'lazy'/>,<StaticImage src =  {b7} loading = 'lazy'/>],
  'f072d25a-39e6-52b0-99d2-96664069b555':[<StaticImage src = {p5} loading = 'lazy'/>, <StaticImage src = {p6} loading = 'lazy'/>,<StaticImage src =  {p7} loading = 'lazy'/>],
  '329a6077-c9cb-505d-b941-39d63cbee7f8':[<StaticImage src = {m5} loading = 'lazy'/>, <StaticImage src = {m6} loading = 'lazy'/>,<StaticImage src =  {m7} loading = 'lazy'/>],
}

const images = {
  'f072d25a-39e6-52b0-99d2-96664069b555':[<StaticImage src = {p1} loading = 'eager'  objectFit = 'scale-down' style = {{height:'80%'}}/>,<StaticImage src =  {p4} loading = 'lazy'  objectFit = 'scale-down' style = {{height:'70%'}}/>],
  '1404fd14-5139-5132-8e12-2778fbd54378':[<StaticImage src = {b1} loading = 'eager'  objectFit = 'scale-down' style = {{height:'80%'}}/>,<StaticImage src =  {b4} loading = 'lazy'  objectFit = 'scale-down' style = {{height:'70%'}}/>],
  '329a6077-c9cb-505d-b941-39d63cbee7f8':[<StaticImage src = {m1} loading = 'eager'  objectFit = 'scale-down' style = {{height:'80%'}}/>,<StaticImage src =  {m4} loading = 'lazy'  objectFit = 'scale-down' style = {{height:'70%'}}/>],
}


export default function QuiltedImageList({id, size, specialStyle, data} ) {
  const windowSize = useWindowSize()
 
  const itemData = images[id]
 
  return (
    <Carousel variant="dark" interval={10000} style={specialStyle}>
      {itemData?.map((image,index) => {
        return (
          <Carousel.Item key={index} className="cslide">
            <div className="carousel-img-wrap">
              {image}
            </div>
          </Carousel.Item>
        )
      })}
      <Carousel.Item key={'witb'} className="cslide">
        <div className="carousel-img-wrap">
          <WhatsInTheBox packetImages={pImages[id]}/>
        </div>
      </Carousel.Item>
    </Carousel>
  )
}

//maybe needs to be moved to page level

