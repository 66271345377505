import React from 'react';
import './HowToUseV2.css'
export default function HowToUse ({packetImages}) {

    return(

        <div className='how-to-use-container'>
            <div className='how-to-use-title'>3 Formulas, Countless Possibilities</div>
       
                
            <div className = 'how-to-use-steps-container'>
                <div className = 'formulas-3-row' style = {{alignItems:'center'}}>

                    <div className = 'formulas-3-title'>
                        Take all 3 for maximum hangover protection
                    </div>
    

                    <div className='formulas-3-or'>
                        OR
                    </div>

                
                    <div className = 'formulas-3-title'>
                        Drink just the formula you need, when you need it
                    </div>
                 
                </div>
               

                <div className = 'formulas-3-row'>
                    <div className = 'formulas-3-column'>
                        <div className = 'formulas-3-image-container'>
                            {packetImages[0]}
                        </div>
                        <strong>Pre-game the pregame</strong> 
                        <div className = 'formulas-3-content'>
                            Drink Pre-game before your first alcoholic beverage to prepare your body for the storm to come. 
                        </div>
                    </div>
                    <div className = 'formulas-3-column'>
                        <div className = 'formulas-3-image-container'>
                            {packetImages[1]}
                        </div>
                        <strong>The drunken Post-game chug</strong>
                        <div className = 'formulas-3-content'>
                         Take Post-game before bed to give your body the fuel it needs to have a restful night and minimize the consequences of your decisions.
                        </div>
                    </div>
                    <div className = 'formulas-3-column'>
                        <div className = 'formulas-3-image-container'>
                            {packetImages[2]}
                        </div>
                        <strong>Sometimes Plan-B is your plan A</strong>
                        <div className = 'formulas-3-content'>
                            Drink Plan-B the morning after a hard night filled with regret and poor decision-making to put a little pep in your step.
                        </div>
                    </div>
                </div>

              

               
               
               
            </div>

            
                   
            
        </div>

    )

}