import React from 'react'
import './BulkOrderQuantity.css'
import BulkOrderQuantityModifier from '../BulkOrderQuantityModifier/BulkOrderQuantityModifier'
import {StaticImage} from 'gatsby-plugin-image'
import {useState, useEffect} from 'react'

const editIcon = '../../images/edit-icon.png'



export default function BulkOrderQuantity({totalQuantity, setBulkOrderQuantity}){

    const [quantity, setQuantity] = useState([])

    useEffect(()=>{
        setQuantity(setDefaultQuantities(totalQuantity))
        setBulkOrderQuantity(setDefaultQuantities(totalQuantity))
    }, [totalQuantity])

    function setDefaultQuantities(totalQuantity){
        let q = totalQuantity/3
        return{
            'f072d25a-39e6-52b0-99d2-96664069b555':{
                id:'f072d25a-39e6-52b0-99d2-96664069b555',
                quant:q,
                name:'Party Pineapple'
            },
            '1404fd14-5139-5132-8e12-2778fbd54378':{
                id:'1404fd14-5139-5132-8e12-2778fbd54378',
                quant:q,
                name:'Blasted Blue Raspberry',
            },
            '329a6077-c9cb-505d-b941-39d63cbee7f8':{
                id:'329a6077-c9cb-505d-b941-39d63cbee7f8',
                quant:q,
                name:'Mango Madness'
            }

        }

    }

    function modifyQuantity(id,direction){
        
        if(direction === 'up'){
            let newQuantity = {...quantity}
            console.log(newQuantity)
            newQuantity[id].quant++
            setQuantity(newQuantity)
            setBulkOrderQuantity(newQuantity)
        }else if(direction === 'down'){
            let newQuantity = {...quantity}
            newQuantity[id].quant--
            setQuantity(newQuantity)
            setBulkOrderQuantity(newQuantity)
        }
        
    }

    const flavorIds = ['f072d25a-39e6-52b0-99d2-96664069b555', '1404fd14-5139-5132-8e12-2778fbd54378', '329a6077-c9cb-505d-b941-39d63cbee7f8']
    return (
        <div className = 'bulk-order-quantity' >
            <div className = 'bulk-order-quantity-top-row'>
                <div className = 'bulk-order-quantity-title'>
                    Flavors
                </div>
               
            </div>
            {
                flavorIds.map(id=>{
                    return (
                        <div className = 'bulk-order-quantity-item' key = {id}>
                            <div className = 'bulk-order-quantity-item-name'>
                            {quantity[id]?.name}
                            </div>
                            <div className = 'bulk-order-quantity-item-quantity'>
                                <BulkOrderQuantityModifier id = {id} quantity = {quantity[id]?.quant} modifyQuantity = {modifyQuantity}/>
                            </div>
                        </div>
                    )
                })
                        
            }
        </div>
    )
}