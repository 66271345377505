
import React from 'react';
import './HealthSection.css'
import { StaticImage } from 'gatsby-plugin-image';
const calories = '../../images/low-calories.png'
const bgImg = '../../images/boxesboozetall.jpg'
const nongmo = '../../images/non-gmo.png'
const vegan = '../../images/vegan.png'
const glutenFree = '../../images/gluten-free.png'
const sugarFree = '../../images/sugar-free.png'
export default function HealthSection (){

    return(

        <div className = 'health-section-main'  >
            <StaticImage src = {bgImg} className = 'health-section-bg' imgStyle={{filter: 'brightness(45%)'}}/>
           <div className = 'health-section-icons'>
                <div className = 'health-section-tr'>
                    <div className = 'health-icon-text-section'>
                        <div className = 'health-section-icon' >
                            <div className = 'health-section-icon-img' style = {{width:'60px',height:'60px'}}>
                                <StaticImage src = {calories} />
                            </div>
                        </div>
                        <div className = 'health-section-icon-text'>12 Calories</div>
                    </div>
                    <div className = 'health-icon-text-section'>
                        <div className = 'health-section-icon' >
                            <div className = 'health-section-icon-img' style = {{width:'60px',height:'60px'}}>
                                <StaticImage src = {sugarFree} />
                            </div>
                        </div>
                        <div className = 'health-section-icon-text'>Sugar Free</div>
                    </div>
                </div>
                <div className= 'health-section-tr'>
                    <div className = 'health-icon-text-section'>
                        <div className = 'health-section-icon' >
                            <div className = 'health-section-icon-img' style = {{width:'60px',height:'60px'}}>
                                <StaticImage src = {vegan} />
                            </div>
                        </div>
                        <div className = 'health-section-icon-text'>Vegan</div>
                    </div>
                    <div className = 'health-icon-text-section'>
                        <div className = 'health-section-icon' >
                            <div className = 'health-section-icon-img' style = {{width:'60px',height:'60px'}}>
                                <StaticImage src = {nongmo} />
                            </div>
                        </div>
                        <div className = 'health-section-icon-text'>Non-GMO</div>
                    </div>
                    <div className = 'health-icon-text-section'>
                        <div className = 'health-section-icon' >
                            <div className = 'health-section-icon-img' style = {{width:'60px',height:'60px'}}>
                                <StaticImage src = {glutenFree} />
                            </div>
                        </div>
                        <div className = 'health-section-icon-text'>Gluten Free</div>
                    </div>
                </div>
              
           </div>

        </div>

    )

}