import { Opacity } from '@mui/icons-material';
import React from 'react';
import './TestimonialsV2.css';
export default function TestimonialsV2 ({image, quote}){

    return(

        <div className='testimonial-v2-main'>
            <div className='testimonial-v2-image'>
                {image}
            </div>
            <div className='testimonial-v2-quote'>
                <div className='t-v2-q-box'>
                <span >"{quote}"</span>
                </div>
               
            </div>
        </div>

    )

}