import React from 'react';
import TestimonialV2 from './TestimonialV2';
import { motion} from "framer-motion";
import { StaticImage } from 'gatsby-plugin-image';
import './TestimonialsV2.css';

const claire = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/claire.jpg?alt=media&token=24c7d272-7e0c-44a7-9d90-51ef94fa3c41';
const wein = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/wein_testimonial.jpg?alt=media&token=acbc665b-26aa-47ec-9b00-057605d082c5';
const lenny = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/lenny.jpg?alt=media&token=eaef5113-523e-43a6-a38e-82e897741e03'
const g1 = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/g1_testimonial.jpg?alt=media&token=15a1410b-5700-4b84-8e7a-46af6160ab61'
const nina = 'https://firebasestorage.googleapis.com/v0/b/bender-images.appspot.com/o/nina.jpg?alt=media&token=f32eea91-35b8-4e33-a9ba-51af4c0b12a8'
const testimonials = [
    {
        image: <StaticImage objectFit = 'cover' className = 'testimonial-v2-image' loading='eager' src= {claire} imgStyle ={{borderRadius: '20px'}}/>,
        quote: "My hangovers are basically nonexistent now."
    },
    {
        image: <StaticImage objectFit = 'cover' className = 'testimonial-v2-image' loading='eager' src= {wein} imgStyle ={{borderRadius: '20px'}}/>,
        quote: "Now I can get hammered with my homies without worrying about how I'll feel the next day."
    },
    {
        image: <StaticImage objectFit = 'cover' className = 'testimonial-v2-image' loading='eager' src= {lenny} imgStyle ={{borderRadius: '20px'}}/>,
        quote: "Not only does it work, it’s absolutely delicious too."
    },
    {
        image: <StaticImage objectFit = 'cover' className = 'testimonial-v2-image' loading='eager' src= {g1} imgStyle ={{borderRadius: '20px'}}/>,
        quote: "After a long night of Jäger bombs, Bender truly saved the day."
    },
    {
        image: <StaticImage objectFit = 'cover' className = 'testimonial-v2-image' loading='eager' src= {nina} imgStyle ={{borderRadius: '20px'}}/>,
        quote: "I’m finally able to be productive again the next day."
    },
]
export default function TestimonialsV2 (){

    return(

        <motion.div 
            className='testimonials-v2-main'
            animate = {{
            backgroundImage:['linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)','linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)','linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'],
            }}
            transition={{
            duration: 5,
            ease: "easeInOut",
            yoyo: Infinity,
            }}
        >
        
            {testimonials.map((testimonial, index) => {
                return <TestimonialV2 key = {index} image = {testimonial.image} quote = {testimonial.quote} height ={300} />
            } 
            )}

        </motion.div>

    )

}