import React from 'react'
import './BulkOrderQuantityModifier.css'
import useStore from '../../context/StoreContext'
import { StaticImage } from 'gatsby-plugin-image'
import {useState} from 'react'
const deleteIcon = '../../images/delete-icon.png'

export default function CartQuantity({ quantity, id, modifyQuantity}){
    let [cartQuantity, setCartQuantity] = useState(quantity)
    

    function incrementUp(){
        modifyQuantity(id, 'up')
    }

    function decrementDown(){
        modifyQuantity(id, 'down')
    }

    


   
    return (
        <div className = "cq-container">
            <div className = 'flavor-quantity'>
                <div className = 'fl-minus' onClick = {decrementDown}>
                    -
                </div>
                <div className = 'cq-current'>{quantity}</div>
                <div className = 'fl-plus' onClick = {incrementUp}>
                    +
                </div>
                

            </div>
                
        </div>
    )
}