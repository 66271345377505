/* eslint-disable */
import React from 'react'
import Layout from '../components/layout'
import ProductAttributes from '../components/ProductAttributes'
import ProductSummary from '../components/ProductSummary'
import SEO from '../components/SEO'
import { track } from '../../tracking'
import {graphql} from 'gatsby'
import {useEffect, useState, useRef} from 'react'
//custom bgs

export default function ProductPageTemplate({location, data}) {
 


  useEffect(()=>{
    let p 
    if (location.state?.id && products[location.state?.id] !== undefined) {
      console.log(products)
      console.log(location.state.id)
      p = setActiveProduct(location.state.id)
  
    }else{
      p = setActiveProduct('1404fd14-5139-5132-8e12-2778fbd54378')
    }
    
    track('Item Detail Page View',{
        'Item Name': 'Hangover Inhibiting Electrolyte Powder',
        'Item Cost': p.priceRangeV2.maxVariantPrice.amount,
        'Discount %': 0,
        'Sale Item': false,
        'Item ID':p.id,
        })
  },[data])

  const ref = useRef(null)
  const [product,setProduct] = useState({})

  let products = {}
  const { nodes } = data.allShopifyProduct

  nodes.forEach((node) => {
    let id =`${node.id}`
    products[id] = node
  })

  function handleScrollDownClick(){
    ref.current?.scrollIntoView({behavior: 'smooth'});
  }


  function setActiveProduct(id){
    
    setProduct(products[id])
    return products[id]
  }


  return (
    <Layout
    location={location}
    background={false}
    
    >
    
    <SEO title={`Hangover Inhibiting Electrolyte Powder | Bender`} description = 'Formulated with electrolytes and designed to help your body process alcohol, Bender is the perfect way to enjoy a night out without having to worry about paying for it the next day.'  />
    {product.id !== undefined &&
    <>
        <ProductSummary product ={product} setActiveProduct = {setActiveProduct} products = {products} data = {data} handleScrollDownClick = {handleScrollDownClick}/>
        <ProductAttributes  id = {product.id} r = {ref} />
        
    </>
    
    
    }
    </Layout>
  )
}

 // Query for all products in Shopify
 export const query = graphql`
	{
		allShopifyProduct {
			nodes {
				id
				title
				handle
				variants {
        	shopifyId
      	}
				priceRangeV2 {
					maxVariantPrice {
						amount
					}
				}
				description
                images {
                    src
                }
				
			}
		},
        mslideShow:allFile(
            filter: {relativeDirectory: {eq: "MImgs"}}
            sort: {order: ASC, fields: base}
            ) {
            edges {
              node {
                id
                relativePath
                base
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          },
          bslideShow:allFile(
              filter: {relativeDirectory: {eq: "BImgs"}}
              sort: {order: ASC, fields: base}
              ) {
            edges {
              node {
                id
                relativePath
                base
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          },
          pslideShow:allFile(
              filter: {relativeDirectory: {eq: "PImgs"}}
              sort: {order: ASC, fields: base}
              ) {
            edges {
              node {
                id
                relativePath
                base
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          },
        
	}
`


