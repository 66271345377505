import * as React from 'react';
import './FAQ.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';


const QAs =  [
    {
        question: 'Do I need to take all three formulas every time I drink? ',
        answer: 'For full-spectrum protection, we encourage the use of all 3 formulas in conjunction. However, they are all designed to be effective if used on their own, depending on your needs in a given drinking event.'
    },
    {
        question:'Which formula is the most important?',
        answer:'If you are only going to take 1 formula, it should be Post-game. Taking post-game before bed is the most important part of the regiment because it improves your sleep quality, helps your body break down the alcohol, and replaces vitamins and minerals that your body needs to recover.'
        
    },
    {
        question:'Does Bender contain caffeine?',
        answer:'Only the Plan-B formula contains caffeine.'
    },
    {
        question:'When is the best time to take Pre-game?',
        answer:'We recommend taking Pre-game immediately before your first alcoholic beverage.',
    },
    {
        question:'When is the best time to take Post-game?',
        answer:'We recommend taking Post-Game 15-30 minutes before you go to sleep.',
    },
    {
        question:'When is the best time to take plan-B?',
        answer:'We recommend taking Plan-B the moment you wake up.'
    },
    {
        question:'Does Bender make you sober more quickly?',
        answer:'No, Bender is not designed to lessen or shorten the effects of alcohol.',
    },
    {
        question:'Why are there 3 formulas?',
        answer:`Bender utilizes different supplements to cater to the body's changing needs throughout a day or night of drinking. Pre-Game prepares you for the consumption of alcohol, Post-Game begins your recovery process after drinking and ensures quality sleep, and Plan-B completes your recovery as well as encourages productivity and wellness the next day.`
    }

]


export default function FAQ() {
  return (
    <div className = 'accordion-section'>
        <div className = 'faq-header'>
            FAQ
        </div>
        {QAs.map((qa, index) => (

            <Accordion key = {index}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                   <div className='faq-question'>{qa.question}</div> 
                </AccordionSummary>
                <AccordionDetails>
                    <div className = 'faq-answer'>{qa.answer}</div>
                </AccordionDetails>
            </Accordion>
            
        
        ))}
    </div>
  )
}