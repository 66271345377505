import React from 'react';

import './WhyBender.css'
import Cta from '../CTA/CTA';
import { StaticImage } from 'gatsby-plugin-image';


const img2 = '../../images/whybender2.jpeg';
const img3 = '../../images/whybender3.jpeg';
export default function WhyBender({handleCTA}){

    return(

    <>
        <div className='why-bender-header'>Why Bender?</div>
      <div  id = 'wb-top' className="why-bender-upper-section" style={{height:'100%',justifyContent:'center', alignItems:'center',}}>
       
        <div className="why-bender-upper-section-l">
            <div className="why-bender-upper-section-r-img-c">
                <StaticImage loading='eager' objectFit = 'scale-down' src ={img2} imgStyle = {{height:'100%', width:'auto', borderRadius:'20px'}}/>
            </div>
        </div>

        <div  className="why-bender-upper-section-r">
            <div className="why-bender-upper-section-l-title">
                Party harder without the consequences
            </div>
            <div className='why-bender-upper-section-l-text'>
             Formulated with electrolytes and designed to help your body process alcohol, Bender is the perfect way to enjoy a night out without having to worry about paying for it the next day.
            </div>
        
        </div>
    </div>
    <div  id = 'wb-bottom' className="why-bender-upper-section" style={{height:'100%',justifyContent:'center', alignItems:'center',}}>
        <div  className="why-bender-upper-section-l">
        <div className="why-bender-upper-section-l-title" >
            Beat your hangover before you start drinking
        </div>
        <div className='why-bender-upper-section-l-text'>
            Bender is more than a hangover cure. We designed it to prevent your hangover in the first place.
        </div>
       
        </div>
        <div className="testimonials-upper-section-r">
        <div className="why-bender-upper-section-r-img-c">
            <StaticImage loading='eager' objectFit = 'scale-down' src ={img3} imgStyle = {{height:'100%', width:'auto', borderRadius:'20px'}}/>
        </div>
        </div>

    </div>
  
</>

    

    )

}