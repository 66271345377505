import React from 'react';

export default function EstimatedDelivery (){
    function ordinal(n) {
        var s = ["th", "st", "nd", "rd"];
        var v = n%100;
        return n + (s[(v-20)%10] || s[v] || s[0]);
        
    }
    function getEstimatedDelivery(){
        let dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let deliveryDay = new Date(new Date().getTime() + (24 * 60 * 60 * 1000 *3 ))
        return `${dayOfWeek[deliveryDay.getDay()]}, ${months[deliveryDay.getMonth()]} ${ordinal(deliveryDay.getDate())}` 
    }
    return(

        <div className = 'estimated-delivery-main'>
            <div className='delivery-by'>Estimated Delivery By:</div>
            <div className = 'delivery-by-date'><strong>{getEstimatedDelivery()}</strong></div>
        </div>

    )

}