import React from 'react'

import './WhatsInTheBox.css'


export default function WhatsInTheBox({packetImages}) {
  return (
    <div
      className="witb-main"
    >
      

      <div className="witb-body">
        <div className="witb-row">
          <div className="witb-row-text">7</div>
          <div className="witb-row-text">x</div>
          <div className="witb-row-img-div">
           
            {packetImages[0]}
          </div>
          <div className="witb-row-text-2">For before you drink</div>
        </div>

        <div className="witb-row">
          <div className="witb-row-text">7</div>
          <div className="witb-row-text">x</div>
          <div className="witb-row-img-div">
            {packetImages[1]}
          </div>
          <div className="witb-row-text-2">For after you drink</div>
        </div>

        <div className="witb-row">
          <div className="witb-row-text">7</div>
          <div className="witb-row-text">x</div>
          <div className="witb-row-img-div">
            {packetImages[2]}
          </div>
          <div className="witb-row-text-2">For the morning after</div>
        </div>
      </div>
    </div>
  )
}
