/* eslint-disable camelcase */
import React from 'react'
import './ProductAttributes.css'
import FAQ from '../FAQ/FAQ'
import Guarantee from '../Guarantee/Guarantee'
import HealthSection from '../HealthSection/HealthSection'
import HealthSectionMobile from '../HealthSection/HealthSectionMobile'
import HowToUseV2 from '../HowToUseV2/HowToUseV2'
import Ingredients from '../Ingredients/Ingredients'
import MediaQuery from 'react-responsive'
import Reviews from '../Reviews/Reviews'
import { StaticImage } from 'gatsby-plugin-image'
import TestimonialsV2 from '../TestimonialsV2/TestimonialsV2'
import WhyBender from '../WhyBender/WhyBender'
import WorthItCalculator from '../WorthItCalculator/WorthItCalculator'



//updated imports

//pineapple

const p5 = '../../images/PSImgs/pineapplesticker1.png'
const p6 = '../../images/PSImgs/pineapplesticker2.png'
const p7 = '../../images/PSImgs/pineapplesticker3.png'

//mango 
const m5 = '../../images/MSImgs/mangosticker1.png'
const m6 = '../../images/MSImgs/mangosticker2.png'
const m7 = '../../images/MSImgs/mangosticker3.png'

//blue raspberry

const b5 = '../../images/BSImgs/blue rasberrysticker1.png'
const b6 = '../../images/BSImgs/blue rasberrysticker2.png'
const b7 = '../../images/BSImgs/blue rasberrysticker3.png'

/*const images = {
  '0': [p5, p6, p7],
  '1': [o5, o6, o7],
  '2': [s5, s6, s7],
  '3': [b5, b6, b7],
  '4': [w5, w6, w7],
  '5': [m5, m6, m7],
}*/

const images = {
  'f072d25a-39e6-52b0-99d2-96664069b555':[<StaticImage src = {p5} loading = 'lazy'/>, <StaticImage src = {p6} loading = 'lazy'/>,<StaticImage src =  {p7} loading = 'lazy'/>],
  '1404fd14-5139-5132-8e12-2778fbd54378':[<StaticImage src = {b5} loading = 'lazy'/>, <StaticImage src = {b6} loading = 'lazy'/>,<StaticImage src =  {b7} loading = 'lazy'/>],
  '329a6077-c9cb-505d-b941-39d63cbee7f8':[<StaticImage src = {m5} loading = 'lazy'/>, <StaticImage src = {m6} loading = 'lazy'/>,<StaticImage src =  {m7} loading = 'lazy'/>],
}
export default ({id, r}) => (
  
  <>
   <div style ={{backgroundImage:'linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)'}}>
      <TestimonialsV2 />
    </div>
    <div style ={{backgroundImage:'linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)'}}>
      <WorthItCalculator />
    </div>
    <div style ={{backgroundImage:'linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)'}}>
      <WhyBender />
    </div>

    <div style ={{backgroundImage:'linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)'}}>
    <HowToUseV2 packetImages={images[id]}/>
    </div>
    <Ingredients />
    <div style ={{backgroundImage:'linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)'}} ref = {r}>
    <Reviews reviewsPerPageSet={5}/>
    </div>
    <div style = {{backgroundImage:'linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'}}>
      
    </div>
    
    <MediaQuery maxWidth= {800}>
      <HealthSectionMobile />
    </MediaQuery>
    <MediaQuery minWidth= {800}>
      <HealthSection/>
    </MediaQuery>
   
  
    <div style ={{backgroundImage:'linear-gradient(45deg, #fff1eb 0%, #ace0f9 100%)'}}>
    <Guarantee dontShowCTA={true}/>
    </div>
    <div style = {{backgroundImage:'linear-gradient(45deg, #fad0c4 0%, #ffd1ff 100%)'}}>
      <FAQ />
    </div>
   
    
  </>
)

/*
<div className = 'ingredients-title'>
    <h2>Active Ingredients</h2>
  </div>
  
  <div className="product-info">
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Electrolytes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        These essential minerals are depleted when you experience dehydration. Since water does not have an adequate supply of electrolytes to replenish them in a timely manner, it is imperative to employ the correct proportion of H₂O with a variety of these minerals. We use a well-tested, research based formula including 5 varieties of electrolytes to ensure maximum rehydration.  

        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>DHM (Dihydromyricetin)</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          Dihydromyricetin (or DHM) is an extract from the Japanese Raisin Tree that has been used for centuries as an anti-alcohol herb and hangover cure in Korean and Chinese traditional medicine. Recent studies suggest that DHM could lower your blood alcohol level, help your body metabolize alcohol faster, and protect your liver from damage and disease.

        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Milk Thistle (Silymarin)</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        Studies on silymarin suggest that it may detoxify some of the harmful compounds in alcohol, thus protecting your liver. Plus, it acts as an antioxidant, neutralizing harmful free radicals that are produced as your body metabolizes alcohol. Additionally, silymarin may turn off the inflammatory signals activated by alcohol.

        </Typography>
      </AccordionDetails>
    </Accordion>
    
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Vitamins</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        A combination of Vitamin C, B6, and B12 help support a variety of metabolic functions as well as promoting proper sleep, a better mood, and more productivity when you’re awake. 
        </Typography>
      </AccordionDetails>
    </Accordion>

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Chamomile</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        Chamomile has been used for centuries to decrease inflammation, help gastrointestinal distress, relieve muscle fatigue, and promote relaxation.         
        </Typography>
      </AccordionDetails>
    </Accordion>*/
