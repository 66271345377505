import React from 'react'

import './OrderOptions.css'
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';


export default function OrderOptions({selected, setSelected}){

 
    

    function handleClick(value){
       
        let newSelected= {
            '1':false,
            '3':false,
            '6':false,
            '12':false,
        }
        newSelected[value] = true
        setSelected(newSelected)
    }



    return (
        <FormControl sx = {{marginTop:'5px'}} className = 'order-options'>
            <Grid container spacing={2}>
                <Grid item xs={6} >
                    <div className = 'order-option' value = '1' onClick = {()=>handleClick('1')} id = {selected['1']? 'selected-option':'not-selected'}>
                        <div className = 'or-top-row'>
                            
                            <div className = 'order-option-title'>
                                1 box
                            </div>
                        </div>
                        <div className = 'or-bottom-row'>

                        </div>
                       
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className = 'order-option' value = '1' onClick = {()=>handleClick('3')} id = {selected['3']? 'selected-option':'not-selected'}>
                        <div className = 'or-top-row'>
                           
                            <div className = 'order-option-title'>
                                3 boxes
                            </div>
                            
                        </div>
                        <div className = 'or-bottom-row'>
                            <div className = 'order-savings'>
                                Save 20%
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className = 'order-option' value = '6' onClick = {()=>handleClick('6')} id = {selected['6']? 'selected-option':'not-selected'}>
                        <div className = 'or-top-row'>
                           
                            <div className = 'order-option-title'>
                                6 boxes
                            </div> 
                        </div>
                        <div className = 'or-bottom-row'>
                            <div className = 'order-savings'>
                                Save 25%
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className = 'order-option'value = '12' onClick = {()=>handleClick('12')} id = {selected['12']? 'selected-option':'not-selected'}>
                        <div className = 'or-top-row'>
     
                            <div className = 'order-option-title'>
                                12 boxes
                            </div>
                        </div>
                        <div className = 'or-bottom-row'>
                            <div className = 'order-savings'>
                                Save 30%
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </FormControl>
       
    )
}