import React, {useState, useContext, useEffect} from 'react'
//snackbar
import './AddToCart.css'
import CircularProgress from '@mui/material/CircularProgress';

import MuiAlert from '@mui/material/Alert'

import Snackbar from '@mui/material/Snackbar'
import useStore from "../../context/StoreContext"
import { track, } from '../../../tracking'

import {Link} from 'gatsby'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})


const products = require('../../../lib/products')

const AddToCart = ({product, purchaseType, preorderMode, bulkOrderQuantity, selected, products}) => {
  const [quantity, setQuantity] = useState(1)
  const [visible, setVisible] = useState(false)
  const [singleItemPrice, setSingleItemPrice] = useState('')
  const { addVariantToCart, addVariantsToCart, addDiscount, loading, cart, checkout} = useStore()
  
  

  const toggleMessage = () => {
    setTimeout(() => {
      setVisible(false)
    }, 1000)
  }
 
  useEffect(() => {
    getSingleItemPrice()
  },[checkout])

  

  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  //use this to add quantity input -> would also need to add form validation back in
  const handleChange = ({target: {value}}) => setQuantity(value)
  //form validation
  const validate = quantity => {
    let error
    const re = /^[0-9\b]+$/

    if (!quantity) error = "Can't be blank"
    if (!re.test(quantity)) error = 'Please enter an integer for the quantity'

    return error
  }



  //Add to cart function
  const handleSubmit = async () => {
    //add handling here for discount type

    if(selected['1']){
      await track('Add To Cart',{
        'Item Name': product.title,
        'Item Cost': product.priceRangeV2.maxVariantPrice.amount,
        'Discount %': 0,
        'Sale Item': false,
        'Item ID':product.id,
        'Suggested Item':false,
        'Subscription Purchase':false,
        "Item Quantity":quantity
      })
  
      let added = await addVariantToCart(product, quantity)
      setOpen(true)
  
      
  
      try {
        typeof window !== 'undefined' &&
          window.gtag('event', 'add_to_cart', {
            price: product.priceRangeV2.maxVariantPrice.amount ,
            flavor: product.title,
            purchaseType: purchaseType,
          })
      } catch (e) {
        console.log(e)
      }
    }else if(selected['3'] || selected['6'] || selected['12']){
      let keys = Object.keys(bulkOrderQuantity)

      let lineItemsToUpdate = []
      for(const key of keys){
        if(bulkOrderQuantity[key].quant > 0){
          lineItemsToUpdate.push({
            variantId: products[key].variants[0].shopifyId,
            quantity: bulkOrderQuantity[key].quant,
          })
        }
      }

     
       const {cost, discount, id, quantity, name} = getBulkOrderInfo()
        
      
        await track('Add To Cart',{
          'Item Name': name,
          'Item Cost': cost,
          'Discount %': discount,
          'Sale Item': false,
          'Item ID':id,
          'Suggested Item':false,
          'Subscription Purchase':true,
          'Bulk Purchase':true,
          "Item Quantity": quantity,
        })
        


        let added = await addVariantsToCart(lineItemsToUpdate,products)
        addBulkOrderDiscount()
    
        
        
        try {
          typeof window !== 'undefined' &&
            window.gtag('event', 'add_to_cart', {
              price: cost,
              flavor: 'bulk order - multiple flavors',
              purchaseType: 'Bulk Order',
              quantity: quantity,
            })
        } catch (e) {
          console.log(e)
        }
      
      setOpen(true)
    
    
    }
  }

  function getBulkOrderInfo(){
    //name 
    //cost 
    //discount
    //id
    //quantity
    let selectedQuantity 
    Object.keys(selected).forEach(key => {
      if(selected[key]){
        selectedQuantity = key
      }
    })

    const id = selectedQuantity + 'bulk-order'

    const { discountPrice, totalPrice, quantity} = calculateAddToCartPrice()
    return {
      cost: totalPrice,
      discount: ((totalPrice - discountPrice) / totalPrice) * 100,
      id: id,
      quantity: quantity,
      name: id
    }
  }

  function calculateCartTotalQuantity(){
    let total = 0
    cart.forEach((item)=>{

      total += item.quantity
    })

    return total
  }

  function getCartItemIds(){
    let ItemIds = cart.map((item)=>{
      return item.product.id
    })
    return ItemIds
  }

  function getCartItemNames(){
    let ItemNames = cart.map((item=>{
      return item.product.title
    }))
    return ItemNames
  }

  function calculateAddToCartPrice(){
    if(!selected['1']){
      let q = 0
      let totalPrice = 0
      Object.keys(bulkOrderQuantity).forEach((key)=>{
        if(bulkOrderQuantity[key].quant > 0){
          let itemPrice = products[key].priceRangeV2.maxVariantPrice.amount 
          totalPrice += itemPrice * bulkOrderQuantity[key].quant
          q += bulkOrderQuantity[key].quant
        }
      })

      let discount 
      if(q<3){
        discount = 0
      }else if(q<6){
        discount = .2
      }else if(q<12){
        discount = .25
      }else{
        discount = .3
      }
      
      let discountPrice = (totalPrice * (1 - discount)).toFixed(2)
      totalPrice = totalPrice.toFixed(2)
      return {discountPrice, totalPrice, quantity:q}
    }
  }

  async function getSingleItemPrice(){
    
    let discount = 0
    const basePrice = product.priceRangeV2.maxVariantPrice.amount
    try{
      let discountValue = checkout.discountApplications[0].value
      console.log('------')
      console.log(checkout.discounts)
      console.log('discoutn value',discountValue)
      if(discountValue.percentage !== undefined){
        let discountPercentage = discountValue.percentage
        discount = ((basePrice * discountPercentage) / 100).toFixed(2)
        console.log(discount)
      }
    }catch(e){
      console.log(e)
    }

    let totalPrice = (basePrice - discount).toFixed(2)
    console.log(totalPrice)
    let price = discount > 0? <span><strike>${basePrice}</strike> ${totalPrice}</span>: <span>${totalPrice}</span>
    console.log(price)
    setSingleItemPrice(price)

  }

  async function addBulkOrderDiscount(){
    if(!selected['1']){
      if(selected['3']){
        let discountAdded = await addDiscount('3 Pack')
      }else if(selected['6']){
        let discountAdded = await addDiscount('6 Pack')
      }else if(selected['12']){
        let discountAdded = await addDiscount('12 Pack')
      }
    }
  }

  function handleCheckout(){
    
    track('Open Checkout',{
      'Cart Size':calculateCartTotalQuantity(),
      'Cart Value':checkout.paymentDue,
      'Cart Item Ids': getCartItemIds(),
      'Cart Item Names': getCartItemNames(),
      'Discount Code':'',
      'Discount Amount':0,
      'Location': 'Added to Cart Popup'
    })
    try{
      typeof window !== 'undefined' &&
      
      window.gtag('event', 'conversion', {
      send_to: 'AW-10829673925/50D6CPXjrtMDEMXr_qso',})
                
           
     

      window.gtag('event', 'Open Checkout', {
        'Cart Size':calculateCartTotalQuantity(),
        'Cart Value':checkout.paymentDue,
        'Cart Item Ids': getCartItemIds(),
        'Cart Item Names': getCartItemNames(),
        'Discount Code':'',
        'Discount Amount':0,
        'Location': 'Cart Page'
      })
    }catch(e){
      console.log('error tracing gtag conversion', e)
    }
    
    
  
    window.open(checkout.webUrl, "_self")
  }



  return (
    <>
      <div className="add-to-cart-button" onClick={handleSubmit}>
        {loading ? 
          <CircularProgress />
        :
        <div className = 'add-to-cart-button-text'>
          {preorderMode? 
            <>
              Add to cart - $19.99
            </>
            :
            <>
            {'Add to cart '}
            {/*purchaseType === 'onetime' ? product.priceRangeV2.maxVariantPrice.amount : ((parseFloat(product.priceRangeV2.maxVariantPrice.amount))* 0.8).toFixed(2)*/}
            {selected['1']? singleItemPrice: <><strike >${calculateAddToCartPrice().totalPrice}</strike> ${calculateAddToCartPrice().discountPrice}</>}
            </>
          }
        </div>
        }
      </div>

    
      

      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} >
        <div>
          <Alert onClose={handleClose} severity="success" sx={{width: '100%', backgroundImage:'linear-gradient(60deg, #abecd6 0%, #fbed96 100%)', color:'black'}}>
            
            <div style = {{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <div style ={{fontWeight:'bold'}}>Added to cart!</div>
            <Link to="/cart/"  style = {{textDecoration:'none', color:'black'}} >
            <div className="go-to-cart-button" >View Cart</div>
            </Link>
              Or
            <div className="go-to-cart-button" onClick = {handleCheckout}>Checkout Now</div>
            </div>
            
          </Alert>
        </div>
      </Snackbar>
    </>
  )
}

export default AddToCart
