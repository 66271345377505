import React from 'react';
import WorthItCalc from './WorthItCalc'
import './WorthItCalc.css';
export default function WorthItCalculator (){

    return(

        <div className='wic-main'>
            <div className='wic-header'>Is Bender worth it?</div>
            <div className='wic-subheader'>Answer a few questions to find out</div>
            <div className='wic-quiz-container'>
                <WorthItCalc/>
            </div>
        </div>

    )

}