import React from 'react'

import './ProductSummary.css'
import AddToCart from '../AddToCart'
import axios from 'axios'
import b from '../../images/bg/br.jpeg'
import BulkOrderQuantity from '../BulkOrderQuantity/BulkOrderQuantity'
import EstimatedDelivery from '../EstimatedDelivery/EstimatedDelivery'
import FormControl from '@mui/material/FormControl';
import GetProductImages from '../GetProductImages/GetProductImages'
import InputLabel from '@mui/material/InputLabel';
import m from '../../images/bg/mango-gradient.jpeg'
import MenuItem from '@mui/material/MenuItem';
import OrderOptions from '../OrderOptions/OrderOptions'
import p from '../../images/bg/pineapple-gradient.jpeg'
import Preorders from '../Preorders/Preorders'
import Select from '@mui/material/Select';
import useWindowSize from '../useWindowSize/useWindowSize'
import MediaQuery from 'react-responsive'

import { StaticImage } from 'gatsby-plugin-image'
import {useEffect , useState} from 'react'
//images
const  fullStar = '../../images/star.png'
const  halfStar = '../../images/half-star.png'
const  emptyStar =  '../../images/empty-star.png'

const freeShipping = '../../images/free-shipping.png'


export default function ProductSummary({product, products, setActiveProduct, data, handleScrollDownClick}) { 
  const apiUrl = 'https://bender-qr.herokuapp.com/'
  const preorderMode = false

  const [rating, setRatingState] = useState(5)
  const [totalReviews, setTotalReviews] = useState(0)
  const [bulkOrderQuantity, setBulkOrderQuantity] = useState({})

  const defaultSelectedState = {
    '1':true,
    '3':false,
    '6':false,
    '12':false,
  }

  const [selected, setSelected] = useState(defaultSelectedState)

  


  const customBackgrounds= {
    'f072d25a-39e6-52b0-99d2-96664069b555':p,
    '1404fd14-5139-5132-8e12-2778fbd54378':b,
    '329a6077-c9cb-505d-b941-39d63cbee7f8':m
  }

  useEffect(()=>{
    getRatingInfo()
  },[])

  function getSelectedQuantity(){
    let selectedQuantity = 0
    for(let key in selected){
      if(selected[key]){
        selectedQuantity = parseFloat(key)
      }
    }
    return selectedQuantity
  }
  
  async function getRatingInfo(){
    await axios.get(apiUrl+'reviews/rating-info')
    .then((response)=>{
     
      setRatingState(response.data.averageRating)
      setTotalReviews(response.data.totalReviews)
    })
    .catch((e)=>{
      console.log(e)
    })

  }

  function ratingToStars(rating){
    let stars = []
    for(let i = 1;  i<=5; i++){
       
      if(i < rating +.25){
        stars.push(1)
      }else if (i < rating + 0.75){
        stars.push(0.5)
      }else{
        stars.push(0)
      }
    }
    let starImgs = stars.map((star,index) => {
      if (star === 1) {
        return <StaticImage className="star-icon-big" src={fullStar} alt="star" key = {index}/>
      } else if (star === 0.5) {
        return <StaticImage className="star-icon-big" src={halfStar} alt="star" key = {index}/>
      } else if (star === 0) {
        return (
          <StaticImage className="star-icon-big" src={emptyStar} alt="star" key = {index}/>
        )
      }
      return''
    })
    return starImgs
  }

    
  function getNumberOfPackets(){
    let selectedQuantity = getSelectedQuantity()
    let numberOfPackets = selectedQuantity * 21
    return numberOfPackets
  }
 

  const [value, setValue] = React.useState('onetime')
  const [subFrequency, setSubFrequency] = React.useState(30)
  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleFrequencyChange = event => {

    setSubFrequency(event.target.value)
  }

  const windowSize = useWindowSize()

  const [flavor, setFlavor] = useState(product.id);

  const handleFlavorChange = (event) => {
    setFlavor(event.target.value);
    setActiveProduct(event.target.value); 
  };
  
  return (
    <div className="product-summary">
      <div
        className="product-summary-image"
        style={{backgroundImage: `url(${customBackgrounds[product.id]})`}}
      >
        {windowSize.width <= 600 && (
          <GetProductImages id={product.id} size={'big'} specialStyle = {{height:'100%'}} data ={data}/>
        )}

        {windowSize.width > 600 && (
          <GetProductImages id={product.id} size={'big'} specialStyle = {{height: '80%', marginTop:'16%'}} data = {data} />
        )}
      </div>

      <div className="product-summary-info">
        <div className="product-summary-title">
          <div className="pst-header">{product.title}</div>

          <div className="pst-subheader">
            Electrolyte powder designed to prevent hangovers
          </div>
        </div>
        <div className="product-summary-rating">
         {ratingToStars(rating)}

          <div className="rev" onClick = {handleScrollDownClick} >{totalReviews} Reviews</div>
        </div>
        <div className = 'ps-numb-packets' style ={{marginTop:'10px', marginLeft:'5px'}}>
          {getNumberOfPackets()} Packets
        </div>
      
        <OrderOptions selected = {selected} setSelected = {setSelected}/>
        
        <div className = 'flavor-select'>
        
          {selected['1']? 
            <FormControl sx={{ m: 1, minWidth: 120, marginLeft:'0px'}} size="small">
              <InputLabel id="demo-select-small">Flavor</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={flavor}
                onChange={handleFlavorChange}
                label="Flavor"
              
                
              >
                <MenuItem value={'1404fd14-5139-5132-8e12-2778fbd54378'}>Blasted Blue Raspberry</MenuItem>
                <MenuItem value={'329a6077-c9cb-505d-b941-39d63cbee7f8'}>Mango Madness </MenuItem>
                <MenuItem value={'f072d25a-39e6-52b0-99d2-96664069b555'}>Party Pineapple</MenuItem>
              </Select>
            </FormControl>
            :
            <BulkOrderQuantity totalQuantity={getSelectedQuantity()} setBulkOrderQuantity = {setBulkOrderQuantity} />
          }
        
        </div>
        {preorderMode? 
          <div className="product-summary-price">
            <div className="pst-price">Save $5 when you pre-order <del>$24.99</del> $19.99 </div> 
            <div className="pst-price">Estimated shipping date: July 26</div> 
          </div>
        :null}





          {/*}
        <div className="product-summary-purchase-frequency">
          <div className="pspf-cf">
            <div className="pspf-cf-title">Choose from</div>
            <div className="pspf-cf-radio">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="onetime"
                    control={<Radio />}
                    label={`One-time purchase - $${product.priceRangeV2.maxVariantPrice.amount}`}
                  />
                  <FormControlLabel
                    value="subscribe"
                    control={<Radio />}
                    label={`Subscribe & save - $${(parseFloat(product.priceRangeV2.maxVariantPrice.amount) * 0.8).toFixed(
                      2,
                    )} (20%)`}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {value === 'subscribe' && (
              <div className="pspf-cf-frequency-select">
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={subFrequency}
                    label="Age"
                    onChange={handleFrequencyChange}
                  >
                    <MenuItem value={30}>
                      <strong>Frequency:</strong> every 30 days
                    </MenuItem>
                    <MenuItem value={60}>
                      <strong>Frequency:</strong> every 60 days
                    </MenuItem>
                    <MenuItem value={90}>
                      <strong>Frequency:</strong> every 90 days
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}s
          </div>
            </div>*/}
        <div className='free-shipping-icon'>
          <StaticImage src ={freeShipping} width = {60} height={60} objectFit='contain'/>
          <EstimatedDelivery />
        </div>
       
        <div className="free-shipping">Free Shipping - Powered by Amazon</div>
        <AddToCart product = {product} purchaseType={value}  preorderMode = {preorderMode} bulkOrderQuantity = {bulkOrderQuantity} selected = {selected} products = {products}/>
        {preorderMode && <Preorders /> }
       
        {/*<ul style = {{listStyleType: 'circle', listStylePosition: 'inside' ,padding:0}}>
          <li>No more morning headaches🤕 or nausea🤢</li>
          <li>Wake up feeling rested💪 after drinking🍻</li>
          <li>Save time⏳ and money💸</li>
          <li>Gluten Free🌾 Non-GMO🧪 Vegan🌿</li>
          </ul>*/}
         <MediaQuery maxWidth= {800}>
          {selected['1'] &&
            <div style= {{ width:'100%',marginTop:'10px'}} >
              <div className = 'summary-benefit-item'>- No more morning headaches🤕 or nausea🤢</div>
              <div className = 'summary-benefit-item'>- Wake up feeling rested💪 after drinking🍻</div>
              <div className = 'summary-benefit-item'>- Prevents hangxiety🥴</div>
              <div className = 'summary-benefit-item'>- Gluten Free🌾 Non-GMO🧪 Vegan🌿</div>
              <div className = 'summary-benefit-item'>- 30-Day Hangover-Free Guarantee🏅</div>

            </div>
          }
        </MediaQuery>

        <MediaQuery minWidth= {1200}>
          {selected['1'] &&
            <div style= {{ width:'100%',marginTop:'10px'}} >
              <div className = 'summary-benefit-item'>- No more morning headaches🤕 or nausea🤢</div>
              <div className = 'summary-benefit-item'>- Wake up feeling rested💪 after drinking🍻</div>
              <div className = 'summary-benefit-item'>- Prevents hangxiety🥴</div>
              <div className = 'summary-benefit-item'>- Gluten Free🌾 Non-GMO🧪 Vegan🌿</div>
              <div className = 'summary-benefit-item'>- 30-Day Hangover-Free Guarantee🏅</div>

            </div>
          }
        </MediaQuery>
      </div>
    </div>
  )
}

/*

<Item.Group>
      

      <Item style={{alignItems: 'center'}}>
        
        <Item.Content>
          <Item.Header> {name}</Item.Header>
          <Item.Description>
            <p> {`$${price}`}</p>
          </Item.Description>
          <Item.Description>
            <p style={{color: 'silver'}}>Free Shipping!</p>
          </Item.Description>
          <Item.Extra>
            <AddToCart productId={id} />
          </Item.Extra>

          <div className="sep"></div>
          <Item.Description>
            <p>
              Bender is the world’s first and only fully comprehensive hangover
              solution of its kind.  Through rigorous human testing we have
              developed a system which can make your worst hangover feel like a
              walk in the park.  Our method involves a pre-game, post-game, and
              morning-after beverage to ensure that your body receives the
              hydration and supplementation you need to function like a
              well-oiled machine from your first drink to your last.  With
              proprietary ingredients which address the body’s changing needs
              throughout the night, Bender will help you start your night on
              your A-game, end your night with productive sleep, and wake up
              ready for the next Bender.
            </p>
          </Item.Description>
          <div className="sep"></div>
          <Item.Description>
            <Header as="h2">Whats in the box?</Header>
            <List bulleted>
              <List.Item>7 packets of Bender Pregame</List.Item>
              <List.Item>7 packets of Bender Postgame</List.Item>
              <List.Item>7 packets of Bender Plan B</List.Item>
            </List>
          </Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>

  */
