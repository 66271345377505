module.exports = {
  productList: [
    {
      "id": '329a6077-c9cb-505d-b941-39d63cbee7f8',
      "title": "Hangover Inhibitor 3 pack",
      "handle": "hangover-inhibitor-3-pack",
      "variants": [
        {
          "shopifyId": null,
        }
      ],
      "priceRangeV2": {
        "maxVariantPrice": {
          "amount": "59.98"
        }
      },
      "description": "",
      "prevPrice":"74.99",
      "savings":"14.99 (20%)",
      "bulkOrder": true
    },

    {
      "id": '329a6077-c9cb-505d-b941-39d63cbee7f8',
      "title": "Hangover Inhibitor 6 pack",
      "handle": "hangover-inhibitor-6-pack",
      "variants": [
        {
          "shopifyId": null,
        }
      ],
      "priceRangeV2": {
        "maxVariantPrice": {
          "amount": "112.45"
        }
      },
      "description": "",
      "prevPrice":"149.94",
      "savings":"37.45 (25%)",
      "bulkOrder": true
    },

    {
      "id":'329a6077-c9cb-505d-b941-39d63cbee7f8',
      "title": "Hangover Inhibitor 12 pack",
      "handle": "hangover-inhibitor-12-pack",
      "variants": [
        {
          "shopifyId": null,
        }
      ],
      "priceRangeV2": {
        "maxVariantPrice": {
          "amount": "209.92"
        }
      },
      "description": "",
      "prevPrice":"299.88",
      "savings":"89.96 (30%)",
      "bulkOrder": true
    },
    
    
    
  ],
}
